@import '__importable.scss';
.button {
	font-weight: 500;
	font-family: var(--poppins);
	border-radius: $radius-2;
	border: 1px solid transparent;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
	line-height: 2.7rem;
	cursor: pointer;
	transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	user-select: none;
	min-width: 2rem;

	span {
		height: inherit;
		font-weight: inherit;
		font-style: inherit;
		font-size: inherit;
		color: inherit;
		justify-content: inherit;
		min-height: inherit;
		min-width: inherit;

		&[data-chevron] {
			display: flex;
			align-items: center;
			gap: 0.8rem;

			> span {
				display: flex;
				flex-flow: row;
				align-items: center;
				gap: 0.4rem;
			}
		}

		&[data-text-animate] {
			width: 100%;
			display: flex;
			justify-content: inherit;
			align-items: inherit;
			font-size: inherit;
			color: inherit;
			background-color: transparent;
			transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms;
			will-change: transform;
			gap: 0.8rem;

			&[data-chevron='true'] {
				justify-content: inherit;

				svg {
					max-width: unset;
					flex-shrink: 0;
				}
			}

			&[data-space-between='true'] {
				justify-content: space-between;
			}
		}

		&[data-add-to-cart-text]::after {
			content: '+Add';
		}

		&[data-with-price='true'] {
			width: 100%;
			color: currentColor;
			justify-content: space-between;
		}
	}

	&:focus-visible {
		outline: 0;
	}

	&:disabled,
	&--disabled,
	[disabled] {
		background-color: $gray-2 !important;
		color: $gray-3 !important;
		border-color: $gray-2 !important;
		cursor: not-allowed;
		// TODO -- find a non-css way to block clicks on disabled anchor links
		pointer-events: none;
	}

	&.remove-state {
		transition: 200ms all cubic-bezier(0.19, 1, 0.22, 1);

		span[data-pricing] {
			transition: 200ms all cubic-bezier(0.19, 1, 0.22, 1);
		}

		span[data-add-to-cart-text]::after {
			content: 'Remove';
		}
	}

	span[data-remove]::after {
		content: 'Remove';
	}

	@media (hover: hover) {
		&:not([disabled]):not([data-remove-effects='true']) {
			&:hover:not(:active):not(:focus-visible) {
				span {
					color: inherit;

					&[data-text-animate] {
						transform: translate3d(2px, -2px, 0);
						transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms;
					}

					&.remove-state {
						background-color: $blue-1;
						border-color: $blue-1;
					}
				}
			}
		}
	}

	&--xsmall {
		padding: 0.9rem 1.6rem 1rem 1.6rem;
		font-size: 1.2rem;
		line-height: 2.1rem;
	}

	&--small {
		padding: 0.9rem 1.6rem 1rem 1.6rem;
		font-size: 1.4rem;
		line-height: normal;
		max-height: 4rem;
	}

	&--medium {
		padding: 1rem 1.6rem 1.1rem 1.6rem;
		font-size: 1.6rem;
		line-height: 1.5;
		min-height: calc(1.6rem * 1.5);

		@media screen and (max-width: $media-sm-max) {
			padding: 1rem;
			font-size: 1.4rem;
			line-height: 2.1rem;
		}
	}

	&--large {
		padding: 1.4rem 1.6rem 1.5rem 1.6rem;
		font-size: 1.8rem;
	}

	&--green {
		background-color: $green-2;
		color: $gray-0;
		border-color: $green-2;

		&:not([disabled]) {
			&:focus-visible {
				border-color: $pink-1;
				box-shadow: 0 0 0 1px $pink-1;
			}

			&:active {
				background-color: $green-shade;
				border-color: $green-shade;
			}

			@media (hover: hover) {
				&:hover:not(:active):not(:focus-visible):not([data-remove-effects='true']) {
					background-color: $green-1;
					box-shadow: 1px -1px 0px 1px $green-shade inset;
				}
			}
		}
	}

	&--blue {
		background-color: $blue-2;
		color: $gray-0;
		border-color: $blue-2;

		span[data-pricing] {
			color: white;
		}

		&:not([disabled]) {
			&:focus-visible {
				border-color: $pink-tint;
				box-shadow: 0 0 0 1px $pink-tint;
			}

			&:active {
				background-color: $blue-3;
				color: $gray-0;
				border-color: $blue-3;
			}

			@media (hover: hover) {
				&:hover:not(:active):not(:focus-visible):not([data-remove-effects='true']) {
					background-color: $blue-1;
					box-shadow: 1px -1px 0px 1px $blue-2 inset;
				}
			}
		}
	}

	&--white {
		background-color: $gray-0;
		color: $blue-2;
		border-color: $gray-2;

		&:not([disabled]) {
			&:focus-visible {
				border-color: $lightblue;
				box-shadow: 0 0 0 1px $lightblue;
			}

			&:active {
				background-color: $gray-2;
				color: $blue-3;
				border-color: $blue-3;
			}

			@media (hover: hover) {
				&:hover:not(:active):not(:focus-visible):not([data-remove-effects='true']) {
					color: $blue-1;
					border-color: $gray-3;
					box-shadow: 1px -1px 0px 1px $gray-3 inset;
				}
			}
		}

		.price {
			color: $gray-4;
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 1.56rem;
		}

		.strikethroughPrice {
			color: $gray-3;
			font-size: 0.8rem;
			font-weight: 500;
			line-height: 1.04rem;
			text-decoration: line-through;
			align-content: center;
		}

		.redPrice {
			font-size: 1.2rem;
			color: $red-1!important;
		}

		.priceContainer {
			display: flex;
			gap: 0.8rem;
		}
	}

	&--transparent-light {
		background-color: rgba(0, 0, 0, 0.08);
		color: $gray-0;
		border-color: $gray-0;

		&:not([disabled]) {
			&:focus-visible {
				border: 2px solid $lightblue;
				background: rgba(0, 0, 0, 0.08);
				backdrop-filter: blur(4px);
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.24);
				backdrop-filter: blur(4px);
				border: 1px solid $white;
			}

			@media (hover: hover) {
				&:hover:not(:active):not(:focus-visible):not([data-remove-effects='true']) {
					background-color: rgba(0, 0, 0, 0.16);
					box-shadow: 1px -1px 0px 1px $gray-0 inset;
					backdrop-filter: blur(4px);
					border: 1px solid $white;
					border-width: 1px 1px 1.25px 1.25px;
				}
			}
		}
	}

	&--transparent-dark {
		background-color: rgba(0, 0, 0, 0.005);
		color: $gray-5;
		border-color: $gray-5;

		&:not([disabled]) {
			&:focus-visible {
				background-color: rgba(0, 0, 0, 0.0001);
				border-color: $lightblue;
				box-shadow: 0 0 0 1px $lightblue;
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.08);
			}

			@media (hover: hover) {
				&:hover:not(:active):not(:focus-visible):not([data-remove-effects='true']) {
					background-color: rgba(0, 0, 0, 0.04);
					color: $gray-5;
					border-color: $gray-5;
					box-shadow: 1px -1px 0px 1px $gray-5 inset;
				}
			}
		}
	}

	&--link {
		color: $blue;

		&:not([disabled]) {
			&:focus-visible {
				border-color: $lightblue;
				box-shadow: 0 0 0 1px $lightblue;
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.08);
				color: $blue-3;
				border-color: $blue-3;
			}

			@media (hover: hover) {
				&:hover:not(:active):not(:focus-visible):not([data-remove-effects='true']) {
					background-color: rgba(0, 0, 0, 0.04);
					color: $blue-tint;
				}
			}
		}
	}
}

.loading {
	border-width: 0.2em;

	[class*='jumping-dots'] {
		position: relative;
		top: -0.8rem;
	}
}
