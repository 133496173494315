@import '__importable.scss';
.default {
	font-weight: 500;
	font-style: normal;
	font-size: 1.8rem;
	font-family: var(--poppins);

    @media screen and (min-width: $media-md) {
		font-weight: 500;
		font-family: var(--platform);
		font-style: normal;
		font-size: 1.6rem;
    }
}
