@import '__importable.scss';
.wrapper {
	@include swatch-wrapper;
}

.shadow{
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.16);
	border-radius: 50%;

	&[data-condition-link='#{false}'] {
		z-index: 0;
	}
}

.swatch {
	@include swatch;
	position: relative;
	z-index: -1;

	@each $name, $color in $swatchColors {
		&[data-lens-color='#{$name}'] {
			background: $color;
			z-index: inherit;
		}
	}

	@each $name, $color in $optionColors {
		&[data-accessory-color='#{$name}'] {
			background: $color;
			z-index: inherit;
		}
	}
}
