@import '__importable.scss';
.container {
    @media screen and (min-width: $media-sm) {

    }
    @media screen and (min-width: $media-lg) {

    }
}

.title {
	> span:first-child{
		color: $blue;
	}

	> span:last-child {
		color: $pink-shade;
	}
}

.pairCarePopover {
	border-radius: 0.4rem;
	padding: 1.6rem;
	opacity: 1;
	background: $white;
	width: 24.4rem !important;
	color: $black;
}

.price {
	color: $gray-4;
}