@import '__importable.scss';
// Mobile ✅
// Ipad
// Laptop
// Wide Desktop

// Gift Card PDP
// Top Frame PDP
// Base Frame PDP
// Accessory PDP
// Cart
// Minicart
$mobile-padding--16: 1.6rem;
$desktop-padding--32: 3.2rem;

.root {
	width: 100%;
	height: auto;
	margin: 0 auto;
	background-color: $gray-1;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas:
		'header'
		'content'
		'sidebar'
		'details'
		'recommendations'
		'reviews';

	&--responsive {
		@extend.root;
		@include noScrollBar;
		display: flex;
		flex-direction: column;
		max-width: $max-width;

		@media screen and (min-width: $media-md-lg) {
			display: grid;
			grid-template-columns: $pdp-left-rail $pdp-right-rail;
			grid-template-areas:
				'content sidebar'
				'details sidebar'
				'recommendations sidebar'
				'reviews sidebar';
		}
	}

	&--pdp-optimization {
		@extend.root;
		display: flex;
		flex-direction: column;
		max-width: $max-width;

		@media screen and (min-width: $media-lg) {
			display: grid;
			max-width: 166.4rem;
			margin: 0 auto;
			background-color: $gray-0;
			grid-template-columns: $pdp-left-rail $pdp-right-rail;
			grid-template-areas:
				'content sidebar'
				'details details'
				'recommendations recommendations'
				'reviews reviews';
		}
	}

	&--cart {
		padding: 0;

		&:not(.root--minicart) {
			@media screen and (width > $media-sm) {
				padding-inline: 0.8rem;
			}

			@media screen and (min-width: $media-md-lg) {
				height: calc(100vh - $header-height--mobile - $announcement-bar-height);
				padding-inline: $mobile-padding--16;
			}

			@media screen and (min-width: $media-xl) {
				padding-inline: $desktop-padding--32;
			}

			@media screen and (min-width: $media-xxl) {
				padding-inline: 6.4rem;
			}

			@media screen and (min-width: $max-width) {
				padding-inline: 12.8rem;
			}

			.content,
			.sidebar {
				background-color: $gray-1;
				border: none;

				@media screen and (width >=$media-md-lg) {
					overflow-y: scroll;
				}
			}

			.content {
				border: none;

				@media screen and (min-width: $media-lg) {
					padding-right: $mobile-padding--16;
				}

				@media screen and (width >=$media-md-lg) {
					padding-left: 0;
					padding-right: $mobile-padding--16;
					height: calc(100vh - 7.7rem);
				}
			}

			.sidebar {
				padding-block: $mobile-padding--16;

				@media screen and (width >=$media-md-lg) {
					padding-bottom: 0;
					height: calc(100vh - 7.7rem);
				}
			}
		}
	}

	&--minicart {
		display: flex;
		flex-flow: column;

		.content,
		.sidebar {
			padding: 0;
			padding-bottom: $mobile-padding--16;

			@media screen and (min-width: $media-md) {
				padding: 0 $mobile-padding--16 $mobile-padding--16 $mobile-padding--16;
			}
		}
	}
}

.content,
.sidebar {
	@include noScrollBar;
	padding: 0;
	overflow: unset;
	z-index: $zindex-2;
}

.content {
	grid-area: content;
	position: relative;
	width: 100%;
	padding: 0;

	&--responsive {
		width: auto;
	}

	&--cart {
		display: flex;
		flex-flow: column nowrap;
		gap: 1.6rem;
	}

	@media screen and (min-width: $media-md) {

		&--responsive {
			width: inherit;
		}
	}
}

.sidebar {
	grid-area: sidebar;
	position: relative;

	&--pdp-optimization {
		overflow-x: auto;
		padding: $mobile-padding--16;
		background-color: $gray-0;
    
		@media screen and (min-width: $media-lg) {
			position: relative;
			width: 100%;
			height: 100%;
			margin-top: 0;
			border-left: none;
			height: fit-content;
			position: sticky;
			top: $headerHeight;
			padding: 3.2rem;
		}
	}
}

.details,
.recommendations,
.reviews {
	background: $gray-0;
	z-index: $zindex-2;
}

.details {
	grid-area: details;
	position: relative;
	width: 100%;

	&--responsive {
		width: 100vw;
		padding: $mobile-padding--16;

		@media screen and (min-width: $media-lg) {
			width: inherit;
			padding: $desktop-padding--32;
		}
	}
}

.recommendations {
	grid-area: recommendations;
	position: relative;
	width: 100%;
	padding: $mobile-padding--16;

	&--responsive {
		width: 100vw;
		padding-right: 0;

		@media screen and (min-width: $media-lg) {
			width: inherit;
			padding-bottom: $desktop-padding--32;
			padding-left: $desktop-padding--32;
		}
	}
}

.reviews {
	grid-area: reviews;
	position: relative;
	width: 100%;

	&--responsive {
		width: 100vw;
		padding: $mobile-padding--16;

		@media screen and (min-width: $media-lg) {
			width: inherit;
			padding: $desktop-padding--32;
		}
	}
}

.cta-container {
	width: 100%;
	margin-inline-start: unset!important;
	padding: $mobile-padding--16;
	margin-block-start: auto;
	border-top: 1px solid $gray-2;
	background-color: $gray-0;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $zindex-2;
	border-radius: $radius-6 $radius-6 0 0;
	border: 1px solid $gray-2;
	
	&--responsive {
		margin-inline-start: unset;

		@media screen and (width < $media-md) {
			padding: 1.2rem $mobile-padding--16;
			position: fixed;
		}

		@media screen and (min-width: $media-md) {
			border-radius: $radius-6;
		}

		@media screen and (min-width: $media-md-lg) {
			bottom: 1.2rem;
		}

		@media screen and (min-width: $media-lg) {
			max-width: 64rem;
			margin-inline-start: -3.2rem;
		}
	}
}