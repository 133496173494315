@import '__importable.scss';
.container {
	padding: 1.6rem;
	border-radius: $radius-4;
	background-color: $gray-1;

	@media screen and (min-width: $media-sm) {
		padding: 1.6rem;
	}
}

.fileName {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 72px;

	@media screen and (min-width: $media-sm) {
		text-overflow: unset;
		white-space: unset;
		overflow: unset;
		width: unset;
	}
}

.option {
	padding: 1.6rem;
}

.bundleTitle {
	font-size: 1.6rem !important;
	font-weight: 500 !important;
}

.checkmark {
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	background: $green-shade;
}

.photo-placeholder {
	margin-top: 1.6rem;
	border-radius: 4px;
	border: 1px solid $gray-2;

	.file-icon {
		color: $green-4;
		background-color: $green-light;
		border-right: 1px solid $gray-2;
		min-width: 4rem;
		height: 4rem;
	}
}
