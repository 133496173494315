@import '__importable.scss';
.container {
	border: 1px solid $gray-2;
	border-radius: 0.4rem;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;

	&Vertical {
		flex-direction: column;

	}

	&LineItem {
		max-width: unset;
		min-height: 7rem;
	}

	&BuildFlow {
		flex-direction: column;
	}

	&NoBorder {
		border: none;
	}

	&Hoverable {
		@include hoverableNoPadding($gray-3);

		&:hover:not(:active):not(:focus-visible) {
			background-color: $gray-1;
		}
	}

	&ProductRemoved {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		span:not(.info-pill) {
			text-decoration: line-through;
		}
	}

	@media screen and (min-width: $media-xl) and (max-width: $media-xxl) {
		// Added because of the current product grid layout (4 products per row) for desktop
		// to keep the same height for all cards
		height: 100%;
	}
}
