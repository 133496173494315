@import '__importable.scss';
.content {
	height: auto;
	padding: 1.6rem;
	position: relative !important;
	justify-content: space-between;
	background-color: $white;

	button[data-add-to-cart] {
		padding: 0.9rem 0.8rem 1rem 0.8rem;
	}

	&Vertical {
		height: 100%;
		border-top: 1px solid $gray-2;

		&Micro {
			padding: 0.8rem;
			border-left: 1px solid $gray-2;
			max-width: 60%;
			&WithTags{
				padding-top: 2.4rem;
			}
		}
	}

	&Horizontal {
		border-left: 1px solid $gray-2;
	}

	&BuildFlow {
		height: 100%;
		justify-content: space-between;
	}

	&Compact {
		flex: 1;
		padding: 1.6rem 0.8rem 0.8rem;
	}

	&Micro {
		display: grid;
		grid-template-columns: minmax(0, 0.9fr) auto; // 0.9fr Helps with readability of content in the card when icons are present
		align-content: center;
		border-left: 1px solid $gray-2;
		padding: 0.8rem;
		&WithTags{
			padding-top: 2.4rem;
		}
	}

	&NoBorder {
		border: none;
		padding-inline: 0;
		margin-bottom: 1rem;
	}

	&BorderLeft {
		height: auto;
		border-top: unset;
		border-left: 1px solid $gray-2;
	}

	&Hoverable {
		background-color: inherit;
		height: unset;

		@media (min-width: $media-md) {
			height: 100%
		}
	}
}