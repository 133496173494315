@import '__importable.scss';
@import '_importable';

html,
body {
	padding: 0;
	margin: 0;
	height: 100%;
	font-family: var(--poppins);
}

html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
}

* {
	margin: 0;
	box-sizing: border-box;
	line-height: calc(1em + 0.5rem);
	-webkit-tap-highlight-color: transparent;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
	border: 0;
}

input,
button,
textarea,
optgroup,
select {
	font-weight: inherit;
	font-size: inherit;
	color: inherit;
	margin: 0;
}

input,
input::before,
input::after {
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
header {
	font-family: var(--platform);
}

address,
article,
aside,
footer,
header,
hgroup,
main,
nav,
section,
p,
a,
input,
button,
select,
textarea,
ul,
li {
	font-family: var(--poppins);
}

#root,
#__next {
	isolation: isolate;
}

a {
	color: inherit;
	background-color: transparent;
	text-decoration: none;
	line-height: inherit;
}

ul,
li {
	list-style: none;
	padding: 0;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

input {
	&[type='search'],
	&[type='number'],
	&[type='email'],
	&[type='password'] {
		-webkit-appearance: none;
		-moz-appearance: none;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

textarea {
	overflow: auto;
	-webkit-appearance: none;
	-moz-appearance: none;
}

[tabindex='-1']:focus {
	outline: none;
}

a,
button,
input,
label,
select,
textarea {
	touch-action: manipulation;
	background: none;
	border: 0;
}

button {
	padding: 0;
	outline: 0;
	border-style: 0;
}

// /* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
	@supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none) {
		html {
			min-height: 100.3%;
		}
	}
}

// Prevents iOS from zooming in on input fields when they are focused
@supports (-webkit-overflow-scrolling: touch) {
	input {
		font-size: 1em;
	}
}

// Temporary fix for Klaviyo discount popup
.needsclick[class*='kl-teaser'] {
	opacity: 0 !important;
	pointer-events: none !important;
	z-index: -1 !important;
}

// Klarna Custom CSS
#klarna-placement {
	max-width: max-content;

	::part(osm-container) {
		border: 0;
		font-family: var(--poppins);
		font-weight: 400;
		font-size: 1rem;
		line-height: 150%;
		padding: 0 !important;
	}

	::part(osm-cta) {
		font-family: var(--poppins);
		font-weight: 400;
		font-size: 1rem;
		line-height: 150%;
		color: $gray-5;

		&:hover {
			color: $gray-3;
			text-decoration: underline;
		}
	}

	::part(osm-message) {
		color: $gray-4;

		@media screen and (width < $media-md) {
			font-size: 1rem;
		}
	}

	::part(osm-cta) {
		@media screen and (width < $media-md) {
			font-size: 1rem;
		}
	}

	::part(osm-logo) {
		font-size: 1.2rem;
	}
}

#klarna-web-sdk-fullscreen,
#klarna-fullscreen-shadow-dom {
	pointer-events: auto;
	iframe {
		height: 902px;
	}
}

klarna-osm-interstitial {
	pointer-events: auto !important;
}

#FreeShippingBanner{
	display: none;
}
